<template>
  <!-- 新增 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="toggle(false)">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          {{isChange ==  true ? '编辑盘点单' : '新增盘点单'}}
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">基础信息</div>
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='90px'
          label-position='right'
          :model='form.data'
          :rules='form.rules'
          :size="subUnitSize"
          :inline="true"
        >
          <el-form-item label="盘点日期" prop="date">
            <el-date-picker
              v-model="form.data.date"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptionsDisable"
              >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <el-button type="primary" class="mb20 mt20" plain size="small" @click="batchAdd">批量添加</el-button>
        <el-button type="primary" class="mb20 mt20" plain size="small" @click="batchImport">批量导入</el-button>
        <span style="font-size:14px;color:#e6a23c;margin-left:10px;">提示：请将今日仓库商品实际剩余可用数量全部录入进来，不可分批上传</span>
        <el-table
          :data="tableData"
          :header-cell-style="{background:'#f5f5f5'}"
          style="width: 100%"
          border
          ref="singleTable"
          highlight-current-row
          tabindex="-1"
          @current-change="handleCurrentChange"
          >
          <el-table-column
            width="80">
            <template slot-scope="{}">
              <i class="el-icon-menu"></i>
            </template>
          </el-table-column>
          <el-table-column
            width="80">
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <el-popconfirm title="确认删除此商品？" @confirm="onMinus(row,$index)">
                <el-button slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
              </el-popconfirm>
              <el-button plain style="display:block;width: 22px;height: 18px;padding: 0;" @click="onAdd(row,$index)">+</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_img"
            label="商品图片"
            width="80px">
            <template slot-scope="{row}">
              <el-image
                v-if="!!row.image"
                style="width: 40px; height: 40px"
                :src="row.image"
                fit="cover"
                :preview-src-list="[row.image]"></el-image>
              <el-image
                v-else
                style="width: 40px; height: 40px"
                :src="require('@/assets/img/logo-icon.jpeg')"
                fit="cover"
                :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_name"
            label="商品名称"
            width="180">
            <template slot-scope="{row,$index}">
              <el-select
                v-model="row.good_name"
                filterable
                remote
                placeholder="请输入关键词"
                size="small"
                :remote-method="remoteMethod"
                :loading="selLoading"
                :ref="`multiSelect+${$index}`"
                @change="changeGoods($event,$index)" value-key="id">
                <el-option v-for="item in goodArr" :label="item.good_name" :value="item" :key="item.id">
                  <div>
                    <span style="float: left">{{ item.good_name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                  </div>
                  <div style="color:#999;margin-top: -5px;" v-if="!!item.norm_name">标名：{{item.norm_name}}</div>
                </el-option>
              </el-select>
              <div style="color:#ff6600;font-size:12px;" v-if="row.id == 0 && row.errText">{{row.errText}}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="商品编码">
          </el-table-column>
          <el-table-column
            prop="order_cate_name"
            label="下单分类">
            <template slot-scope="{row}">
              <span>{{row.order_cate_name}}</span>
              <span v-if="!!row.order_two_name"> / {{row.order_two_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位"
            width="90">
          </el-table-column>
          <el-table-column
            prop="name"
            label="规格"
            min-width="120"
            max-width="160">
            <template slot-scope="{row,$index}">
              <el-select
                v-if="row.type == 2"
                v-model="row.name"
                size="mini"
                @change="changeSpec($event,$index)" value-key="id">
                <el-option v-for="(v,i) in row.spec" :label="v.name" :value="v" :key="i">
                  <div style="display:flex;justify-content: space-between;">
                    <span style="float: left">{{ v.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">（{{ v.unit_name }}）</span>
                  </div>
                </el-option>
              </el-select>
              <span v-else>{{row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="sys_quantity"
            label="系统库存"
            width="100px">
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="*实际库存"
            width="200px">
            <template slot-scope="{row,$index}">
              <div style="display:flex;align-items: center;">
                <el-input
                  v-model="row.quantity"
                  :ref="'mark'+$index"
                  placeholder="请输入"
                  size="small"
                  style="width:120px;margin-right:5px;"
                  @blur="countQuantity(row,$index)"
                  @keyup.enter.native="nextFocus"
                ></el-input>
                <span v-if="row.newNum > 0" style="color:red;width:54px;">+{{row.newNum}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="num_curr"
            label="盘点差异数量"
            width="110px">
            <template slot-scope="{row}">
              <span>{{row.num_curr || 0}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footerWrap">
        <el-button size="medium" @click="toggle(false)">取消</el-button>
        <el-button type="primary" size="medium" @click="confirm" :loading="form.loading">保存</el-button>
      </div>
    </div>

    <!-- 批量选择商品弹窗 -->
    <BatchGoods ref="batchGoods" :merchant_id="form.data.merchant_id" @sendgoodslist="harvestgoodslist" v-if="isShowDialog"></BatchGoods>

    <!-- 导入文件弹窗 -->
    <ImportDailog ref="importDailog" @sendgoodslist="harvestgoodslist"></ImportDailog>
  
  </div>
</template>

<script>
  import BatchGoods from "./BatchGoods.vue"
  import ImportDailog from './importDailog.vue'
  import { mixinTable } from "@/mixins/table.js";
  import { getStorage } from '@/storage'
  export default {
    name: 'orderAdd',
    props:['sampleFile'],
    mixins: [mixinTable],
    components: {
      BatchGoods,
      ImportDailog,
    },
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        pickerOptionsDisable: {
          disabledDate(time) {
            return time.getTime() > Date.now(); // 禁用未来日期
          },
        },
        table:{
          params: {
            page:1,
            count: 10,
          },
          total: 0,
          data:[],
        },
        tableData: [{id:'',name:'',quantity:'',spec:[{}]}],
        specArr:[],
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            date: '',
            spec: [
              {
                id: 1, //明细ID
                spec_id: '', //规格ID
                quantity: '', //实际库存
                amount: '' //实际金额
              }
            ]
          },
          rules: {
            date: [{ required: true, message:'请选择', trigger: 'change'}],
          },
        },
        fileList: [],
        isShowDialog: false,
        goodArr: [], // 商品列表
        selLoading: false,
        currentRow: null,
      }
    },
    methods: {
      // 添加订单、采购单、盘点单，列表输入商品名称查询商品
      remoteMethod(query) {
        if(query !== '') {
          this.selLoading = true;
          this.$http.get('/admin/goods/specList', { params:{ page: 1, count: 1000, good_name: query, }}).then(res => {
            if(res.code === 1) {
              this.goodArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      getDetail(row) {
        this.currRow = row;
      },
      getTable() {
        let _params = {
          id: this.currRow.id,
          page:1,
          count:1000
        }
        this.$http.get('/admin/stock/info', { params: _params}).then(res => {
          if(res.code == 1) {
            this.tableData = res.data.list.map(v => {
              return {
                ...v,
                id: v.spec_id, // 规格id
                name: v.spec_name, // 规格名称
                num_curr: (Number(v.quantity) - Number(v.sys_quantity)).toFixed(0), // 盘点差异数量 = 实际库存 - 系统库存
                price_curr: (Number(v.amount) - Number(v.sys_amount)).toFixed(2), // 盘点差异金额 = 实际金额 - 库存金额
              }
            }); // 商品信息
          }
        })
      },
      reset(done) {
        this.isChange = false;
        this.form.data.date = '';
        this.tableData = [{id:'',name:'',spec:[{}]}];
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 减
      onMinus(row,index) {
        console.log(row,index)
        if(this.tableData.length > 1) {
          this.tableData.forEach((v,i) => {
            if(index == i) {
              this.tableData.splice(i, 1)
            }
          })
          this.specArr = this.specArr.filter(j => j != row.id);
        } else {
          this.$message.warning('最后一条数据了!')
        }
      },
      // 加
      onAdd(row,index) {
        let str = {
          image: require('@/assets/img/default.jpg'),
          good_name:'',
          num:'',
          remark:'',
          spec:[{}]
        }
        this.tableData.splice(index+1,0,str); // 添加到指定位置
        this.$set(this.tableData,row,index)
        this.$nextTick(() => {
          this.$refs[`multiSelect+${index + 1}`].focus()
        })
        this.goodArr = [];
      },
      // 保存
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            console.log(this.tableData)
            let currArr = this.tableData.filter(j => !!j.good_id ) // 过滤掉列表中未选商品的空的数据
            if(currArr.length == 0) {
              this.$message.warning('请先添加商品')
              return
            }
            let _spec = currArr.map(v => {
              return {
                spec_id: v.id,
                quantity: v.quantity,
              }
            })
            console.log(_spec)
            let _params = {
              id: this.currRow.id,
              date: this.form.data.date,
              spec: _spec
            }
            console.log(_params)

            // return
            let url = !!this.isChange ? '/admin/stock/edit' : '/admin/stock/add';
            this.form.loading = true;
            this.$http.post(url, _params).then(res => {
              if(res.code == 1) {
                this.toggle(false)
                this.$emit("refresh")
                this.$message.success('操作成功！')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      one(arr) {
        let sum = 0;
        arr.map(function (item) {
          sum += Number(item.num)
        })
        return sum
      },
      
      // 批量添加弹窗
      batchAdd() {
        this.isShowDialog = true
      },
      //批量导入表格识别
      batchImport() {
        let dom = this.$refs.importDailog;
        dom.toggle(true);
        let obj = {
          sampleFile: this.sampleFile, // 样表
        }
        dom.getDetail(obj);
        dom = null;
      },
      // 接受父组件传过来的商品列表
      harvestgoodslist(val) {
        console.log(val,"00000")
        console.log(this.tableData,"22222")
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        // return
        val.forEach(item => {
          if(this.specArr.indexOf(item.id) >= 0){
            console.log("0")
            if(item.id == 0) {
              this.tableData.push({
                ...item,
                num_curr: this.subtotalFun(item.quantity,item.sys_quantity), //盘点差异数量 = 实际库存-系统库存
              });
              this.specArr.push(item.id);
            } else {
              this.tableData.map(value => {
                if(value.id == item.id){
                  value.quantity = Number(value.quantity) + Number(item.quantity); // 实际库存数量
                  value.num_curr = this.subtotalFun(value.quantity,value.sys_quantity) //盘点差异数量 = 实际库存-系统库存
                  value.newNum = item.quantity; //要显示当前加的实际库存数量
                }
              });
            }
          } else {
            console.log("1")
            this.tableData.push({
              ...item,
              num_curr: this.subtotalFun(item.quantity,item.sys_quantity), //盘点差异数量 = 实际库存-系统库存
            });
            this.specArr.push(item.id);
          }
        });
        console.log(this.tableData,"111111")
        this.isShowDialog = false;
      },
      // 导入商品，多规格时选择规格
      changeSpec(value,index) {
        console.log(value,"value")
        if(this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning('已经添加过该规格')
          this.tableData[index].name = ''; // 当前行规格值清空
          this.tableData[index].id = 0; // 当前行规格值清空
          return
        } else {
          this.specArr.push(value.id);
          let _currRow = {
            id: value.id,
            name: value.name,
            quantity: value.quantity, // 实际库存
            sys_quantity: value.sys_quantity, // 系统库存
            unit_name: value.unit_name,
            type: 1,
            num_curr: this.subtotalFun(value.quantity,value.sys_quantity), // 计算盘点差异数量
          }
          Object.assign(this.tableData[index], _currRow)
        }
      },
      // 选择商品
      changeGoods(value, index) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        console.log("*1")
        if(this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning("已经添加过该规格");
          this.tableData[index].good_name = {}; // 当前行搜索值清空
          this.goodArr = []; // 当前行筛选数据清空
          return; 
        }
        console.log("*2")
        this.specArr.push(value.id);
        let _currRow = {...value,quantity: this.tableData[index].quantity,spec:[{}]};
        this.tableData[index] = _currRow;
        this.$set(this.tableData, index, {..._currRow})
      },
      // 计算盘点差异数量 = （实际库存-系统库存）
      subtotalFun(quantity, sys_quantity) {
        let num_curr = 0;
        return num_curr = (Number(quantity) - Number(sys_quantity)).toFixed(2) * 1// 计算盘点差异
      },
      // 计算盘点差异数量 = （实际库存-系统库存）
      countQuantity(row,index) {
        const reg = /^[\d]+$/;
        // 单位只有是（克、两、斤、千克）的时候可以输小数点一位小数，其他的请控制不允许输小数点
        if(row.unit_name == '克' || row.unit_name == '两' || row.unit_name == '斤' || row.unit_name == '千克') {
          if (row.quantity && !reg.test(row.quantity)) {
            row.quantity = row.quantity.match(/\d+(\.\d{0,1})?/) ? row.quantity.match(/\d+(\.\d{0,1})?/)[0] : '';
          }
        } else{
          if (row.quantity && !reg.test(row.quantity)) {
            row.quantity = row.quantity.replace(/[^\d]/g, '');
          }
        }
        if(!!row.quantity) {
          row.num_curr = ((Number(row.quantity)* 100/100 - Number(row.sys_quantity)*100/100)).toFixed(2) * 1
          this.$set(this.tableData, index, {...row,quantity: row.quantity})
        }
      },
      setCurrent(row) {
        this.$refs.singleTable.setCurrentRow(row);
      },
      handleCurrentChange(val) {
        this.currentRow = val;
      },
      nextFocus() {
        const len = this.tableData.length;
        const val = this.currentRow
        this.tableData.forEach((v, i) => {
          if (v === val) {
            if (i < len - 1) {
            this.setCurrent(this.tableData[i + 1])
            this.$refs['mark' + (i + 1)].focus()
            } else {
              this.setCurrent(this.tableData[0])
              this.$refs['mark' + (0)].focus()
            }
          }
        })
      },
     
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.el-select-dropdown__item {
  display: flex;
  flex-direction: column;
  height: auto !important;
}
</style>



