<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          详情
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">基础信息</div>
        <el-descriptions title="" :column="4" >
          <el-descriptions-item label="盘点单号">{{info.stock_no}}</el-descriptions-item>
          <el-descriptions-item label="盘点日期">{{info.date}}</el-descriptions-item>
          <el-descriptions-item label="制单人">{{info.admin_name}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{info.create_time}}</el-descriptions-item>
          <el-descriptions-item label="状态">{{toStr({1:'待审核',2:'审核通过',3:'审核拒绝'},info.status)}}</el-descriptions-item>
          <el-descriptions-item label="审核时间" v-if="info.status != 1">{{info.check_time}}</el-descriptions-item>
          <el-descriptions-item label="拒绝原因" v-if="info.status == 3">{{info.reason}}</el-descriptions-item>
       </el-descriptions>
        <el-input
          style="width:260px;margin-bottom:10px;"
          v-model.trim="form.data.keyword"
          placeholder="请输入商品名称，商品编码"
          size="small"
          clearable
          @clear="getTable"
          @keydown.enter.native="getTable"
        >
          <el-button slot="append" icon="el-icon-search" @click="getTable"></el-button>
        </el-input>

        <el-table
          :data="tableData"
          style="width: 100%"
          border>
          <el-table-column type="index" label="ID" fixed></el-table-column>
          <el-table-column
            prop="good_name"
            label="商品名称"
            width="160px"
            fixed>
            <template slot-scope="{row}">
              <div style="align-items: center;">
                <div style="margin-left:10px;">{{row.good_name}}</div>
                <div>{{row.code}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="order_cate_name"
            label="下单分类">
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位">
          </el-table-column>
          <el-table-column
            prop="spec_name"
            label="规格">
          </el-table-column>
          <el-table-column
            prop="purchase_price"
            label="存货单价">
            <template slot-scope="{row}">
              <span>¥{{row.purchase_price}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="实际库存">
          </el-table-column>
          <el-table-column
            prop="amount"
            label="库存金额">
            <template slot-scope="{row}">
              <span>¥{{row.amount}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="sys_quantity"
            label="系统库存">
          </el-table-column>
          <el-table-column
            prop="loss_quantity"
            label="盘点差异">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mixinTable } from "@/mixins/table.js";
  export default {
    name: 'StoreCheckDetail',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        info: {},
        tableData: [],
        table: {
          total:0,
        },
        
        loading: false,
        form: {
          loading: false,
          data: {
            keyword: ''
          },
        },
        currRow: {},
      }
    },
    methods: {
      getDetail(row) {
        this.currRow = row
        this.getTable();
      },
      getTable() {
        let _params = {
          id: this.currRow.id,
          keyword: this.form.data.keyword,
          page:1,
          count:1000
        }
        this.$http.get('/admin/stock/info', { params: _params}).then(res => {
          if(res.code == 1) {
            this.info = res.data.info;
            this.tableData = res.data.list;
          }
        })
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
        this.tableData = [];
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
</style>
