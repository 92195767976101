<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="创建日期" prop="selDate">
          <el-date-picker
            v-model="table.params.selDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="盘点日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            align="right"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品名称" prop="good_name">
          <el-input
            v-model.trim="table.params.good_name"
            placeholder="请输入商品名称"
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showAdd" >新增盘点单</el-button>
        <el-popover
          placement="top-start"
          title=""
          width="260"
          trigger="hover"
          content="库存盘点数量将用以更新商品现有库存数据,且用以盘点当天操作采购审核计算订单销售商品可使用库存数量，若有可用库存请在当天下采购单前上传可用库存盘点单并确认。">
          <span slot="reference" style="margin-left:20px;font-size:14px;color:#e6a23c;cursor: pointer;"><i class="el-icon-warning-outline"></i>盘点说明</span>
        </el-popover>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:stock_no="{row}">
        <el-link type="primary" @click="showDetail(row)">{{row.stock_no}}</el-link>
      </template>
      <!-- 库存差异(有确认时间并且更新库存的，展示已报损或已入库) is_change 1:更新库存 0:未更新-->
      <template v-slot:loss_quantity="{row}">
        <span :class="Number(row.loss_quantity) < 0 ? 'redColor' : ''" style="margin-right:5px;">{{row.loss_quantity}}</span>
        <span v-if="Number(row.loss_quantity) < 0 && !!row.check_time && row.is_change == 1" class="redColor">(已报损)</span>
        <span v-if="Number(row.loss_quantity) > 0 && !!row.check_time && row.is_change == 1">(已入库)</span>
      </template>
      <!-- 确认时间 -->
      <template v-slot:check_time="{row}">
        <span>{{!!row.check_time ? row.check_time : '待确认'}}</span>
      </template>
      <!-- 库存金额 -->
      <template v-slot:amount="{row}">
        <span>¥{{row.amount}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="plexportDataGoods(row)">导出</el-button>
        <!-- 没有确认时，可以确认、编辑、删除操作 -->
        <template v-if="row.check_time == ''">
          <el-button slot="reference" type="text" @click="view(row)">确认</el-button>
          <el-button type="text" @click="showEdit(row)">编辑</el-button>
          <el-button type="text" @click="remove(row)">删除</el-button>
        </template>
      </template>
    </VTable>

    <!-- 新增 -->
    <Add ref="add" :sampleFile="sampleFile" @refresh="getTable"></Add>
    <!-- 编辑 -->
    <edit ref="edit" :sampleFile="sampleFile" @refresh="getTable"></edit>
    <!-- 详情 -->
    <detail ref="detail" @refresh="getTable"></detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Add from './components/Add.vue'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: 'StoreCheckEdit',
  components: {
    Add,
    Edit,
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "stock_no", label: "单号", hidden: false },
        { name: "date", label: "盘点日期", hidden: false},
        { name: "quantity", label: "盘点库存", hidden: false},
        { name: "amount", label: "库存金额", hidden: false},
        { name: "sys_quantity", label: "系统库存", showTooltip: true, hidden: false},
        { name: "loss_quantity", label: "库存差异",width:'120', hidden: false},
        { name: "admin_name", label: "创建人", width: "120", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "check_time", label: "确认时间", hidden: false},
        { name: "action", label: "操作", width:'180', hidden: false},
      ],
      sampleFile: '',
      table: {
        loading: false,
        params: {
          good_name: '',
          date: '',
          selDate:[],
          start_time: [],
          end_time: [],
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      goodsArr: [],
      isShow: false,
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      let _date = this.table.params.selDate;
      if(!!_date && _date.length > 0) {
        _params.start_time = _date[0]
        _params.end_time = _date[1]
      } else {
        _params.start_time = '';
        _params.end_time = '';
      }
      delete _params.selDate
      this.table.loading = true;
      this.$http.get('/admin/stock/list', {params: _params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.sampleFile = res.data.file;
        }
      })
    },
    // 添加订单
    showAdd(row) {
      let dom = this.$refs.add;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    showDetail(row) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/stock/delete"
      );
    },
    // 同意
    view(row) {
      let date = this.$moment(new Date()).format("YYYY-MM-DD"); //当前日期
      let str = row.date == date ? '此操作代表您已确认库存数量无误，商品现有库存将以本盘点单为准覆盖，今日订单中审核商品需采购数量将优先使用今天盘点库存数量' : '该盘点日期不属于今日，确认后无法更新现有库存以及采购审核计算可使用库存，仅为记录作用，请知悉'
      let text = row.date == date ? '确认更新' : '确认提交'
      console.log(date)
      console.log(row.date)
      this.$confirm(str, '温馨提示：', {
        confirmButtonText: text,
        showCancelButton: false,
        center: true
      }).then(() => {
        this.$http.post('/admin/stock/operation', {id: row.id, status:1}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功')
            this.getTable();
          }
        })
      }).catch(() => {});
    },
    // 查询商品
    getGoods() {
      this.$http.post("/admin/goods/list", {page:1,count:10000}).then((res) => {
        if(res.code == 1) {
          this.goodsArr = res.data.list;
        }
      })
    },
    // 导出
    plexportDataGoods(row) {
      let url = '/admin/stock/export'
      let str = ""
      let obj = {
        id: row.id
      }
      Object.keys(obj).forEach(item => {
          str += `&${item}=${obj[item]}`
      })
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
   
  }
}
</script>
